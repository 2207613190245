import { UserType } from "@/common/types";
import { Link } from "@inertiajs/react";
import { AccountCircle, ArrowDropDown } from "@mui/icons-material";
import {
  Button,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MouseEvent, useMemo, useState } from "react";

const UserMenu = ({ user }: { user: UserType }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(
    `(max-width:${theme.breakpoints.values.sm}px)`,
  );

  const parsedUserLabel = useMemo(() => {
    if (user.name) {
      return user.name;
    }
    return user.email.substring(0, user.email.indexOf("@"));
  }, [user]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          maxWidth: isMobile ? "43px" : "200px",
          minWidth: "0px",
          padding: "8px",
          color: "white",
          "&:hover": {
            backgroundColor: theme.palette.surfaces.medium,
          },
        }}
      >
        <AccountCircle />
        <Typography
          sx={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            display: isMobile ? "none" : "block",
          }}
        >
          {parsedUserLabel}
        </Typography>
        <ArrowDropDown
          sx={{
            display: isMobile ? "none" : "block",
          }}
        />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          "& .MuiMenu-paper": {
            backgroundColor: theme.palette.surfaces.dark,
            minWidth: "120px",
            "& a": {
              color: "white",
            },
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem>
          <Link href="/logout">Logout</Link>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserMenu;
